import * as React from "react"
import { Link } from "gatsby"
import Logo from "../images/pyxel-logo-white_3.png"
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function Navbar() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <div className="fixed inset-x-0 z-10 border-b-2 border-gray-600" style={{ backgroundColor: "#303030" }}>
            <div className=" text-white text-md font-normal">
                <div className="flex items-center justify-between px-2 md:px-6 py-3 container mx-auto">
                    <div className="w-48">
                        <Link to="/"><img title="Pyxel Development Web Design and SEO" alt="Pyxel Development Web Design and SEO" src={Logo} /></Link>
                    </div>
                    <div className="items-center md:space-x-16 hidden lg:flex">
                        <Link to="/">
                            <button className="hover:text-gray-500" type="button">
                                <span>Home</span>
                            </button>
                        </Link>
                        <Link to="/about">
                            <button className="hover:text-gray-500 " type="button">
                                <span>About Us</span>
                            </button>
                        </Link>
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button className="inline-flex px-4 py-2 hover:text-gray-500 focus:outline-none">
                                    Services
                                    <ChevronDownIcon className="w-5 h-6 ml-2 -mr-1 text-violet-900 hover:text-gray-500" aria-hidden="true" />
                                </Menu.Button>
                            </div>
                            <Transition as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute w-60 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/sacramento-web-development">
                                                    <button
                                                        className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                        {active ? (
                                                            <WebDevActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        ) : (
                                                            <WebDevInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />)}
                                                        <span className="px-1">Website Development</span>
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/sacramento-web-design">
                                                    <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                        {active ? (
                                                            <WebDesignActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        ) : (
                                                            <WebDesignInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        )}
                                                        <span className="px-1">Website Design</span>
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="px-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/sacramento-web-maintenance">
                                                    <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full py-2 text-sm`} >
                                                        {active ? (
                                                            <WebMaintenanceActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        ) : (
                                                            <WebMaintenanceInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        )}
                                                        <span className="px-1">Website Maintenance</span>
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/sacramento-digital-marketing">
                                                    <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`} >
                                                        {active ? (
                                                            <DigitalMarketingActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        ) : (
                                                            <DigitalMarketingInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                        )}
                                                        <span className="px-1">Digital Marketing</span>
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="px-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/sacramento-ecommerce">
                                                    <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                        {active ? (
                                                            <EcommerceActive className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                        ) : (
                                                            <EcommerceInactive className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                        )}
                                                        <span className="px-1">Ecommerce</span>
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="px-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/sacramento-seo">
                                                    <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                        {active ? (
                                                            <SEOActiveIcon className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                        ) : (
                                                            <SEOInactiveIcon className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                        )}
                                                        <span className="px-1">Search Engine Optimization</span>
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                        <Link to="/blog">
                            <button className="hover:text-gray-500" type="button">
                                <span>Blog</span>
                            </button>
                        </Link>
                    </div>
                    <div className="flex items-center mx-auto lg:mx-0">
                        <Link to="/contact">
                            <button className="m-5 relative hidden sm:flex h-10 py-2 px-6 text-white sm:mx-16 md:mx-20 lg:mx-5 transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-gray-500">
                                <span>Contact Us</span>
                            </button>
                        </Link>
                        <ul className="flex px-20 sm:px-0 py-7 space-x-2">
                            <a className=" text-white hover:text-gray-500" href="https://www.facebook.com/pyxeldevelopment/" target="blank" rel="noreferrer" title="Visit Pyxel Development on Facebook" alt="Visit Pyxel Development on Facebook"><svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343
                             21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                            </svg>
                            </a>
                            <a href="https://www.instagram.com/pyxeldevelopment/" target="_blank" rel="noreferrer" title="Visit Pyxel Development on Instagram" alt="Visit Pyxel Development on Instagram" className="text-white hover:text-gray-500"><span className="sr-only">Instagram</span><svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 
                                4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 
                                01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 
                                2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 
                                2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0
                                00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                    clipRule="evenodd"></path></svg></a>
                        </ul>
                    </div>
                    <button className=" text-white cursor-pointer text-xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
                        <Hamburger className="w-5 h-5" aria-hidden="true" />
                    </button>
                    <div className={"" + (navbarOpen ? "flex" : " hidden")}>
                        <div className="absolute mt-14 inset-x-0 border-t border-gray-300 transition transform origin-top-right z-50 lg:hidden" >
                            <div className=" shadow-lg font-display">
                                <div className="shadow-xs bg-white text-black">
                                    <div><Link to="/" className="block py-4 px-8 font-semibold border-b border-gray-300 font-semibold text-base transition-colors duration-150 ease-in button-active-bhvr hover:text-gray-500"><span>Home</span></Link>
                                        <div className="py-4 px-8  border-b border-gray-300">
                                            <Link to="/about" className="block font-semibold font-semibold text-base transition-colors duration-150 ease-in button-active-bhvr hover:text-gray-500"><span>About Us</span></Link>
                                        </div>
                                        <div className="py-4 px-8 border-b border-gray-300">
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <Menu.Button className="inline-flex font-semibold block mb-2 hover:text-gray-500 focus:outline-none">
                                                        Services
                                                        <ChevronDownIcon className="w-5 h-6 ml-2 -mr-1 text-violet-900 hover:text-gray-500" aria-hidden="true" />
                                                    </Menu.Button>
                                                </div>
                                                <Transition as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute w-60 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="px-1 py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <Link to="/sacramento-web-development">
                                                                        <button
                                                                            className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                                            {active ? (
                                                                                <WebDevActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            ) : (
                                                                                <WebDevInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />)}
                                                                            <span className="px-1">Website Development</span>
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <Link to="/sacramento-web-design">
                                                                        <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                                            {active ? (
                                                                                <WebDesignActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            ) : (
                                                                                <WebDesignInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            )}
                                                                            <span className="px-1">Website Design</span>
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                        <div className="px-1 ">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <Link to="/sacramento-web-maintenance">
                                                                        <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full py-2 text-sm`} >
                                                                            {active ? (
                                                                                <WebMaintenanceActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            ) : (
                                                                                <WebMaintenanceInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            )}
                                                                            <span className="px-1">Website Maintenance</span>
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <Link to="/sacramento-digital-marketing">
                                                                        <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`} >
                                                                            {active ? (
                                                                                <DigitalMarketingActiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            ) : (
                                                                                <DigitalMarketingInactiveIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                            )}
                                                                            <span className="px-1">Digital Marketing</span>
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                        <div className="px-1 ">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <Link to="/sacramento-ecommerce">
                                                                        <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                                            {active ? (
                                                                                <EcommerceActive className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                                            ) : (
                                                                                <EcommerceInactive className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                                            )}
                                                                            <span className="px-1">Ecommerce</span>
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                        <div className="px-1 ">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <Link to="/sacramento-seo">
                                                                        <button className={`${active ? 'bg-violet-500 text-gray-400' : 'text-gray-900'} group flex rounded-md items-center w-full  py-2 text-sm`}>
                                                                            {active ? (
                                                                                <SEOActiveIcon className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                                            ) : (
                                                                                <SEOInactiveIcon className="w-5 h-5 mr-2 text-violet-400" aria-hidden="true" />
                                                                            )}
                                                                            <span className="px-1">Search Engine Optimization</span>
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        <Link className="block py-4 px-8 font-semibold border-b border-gray-300 font-semibold text-base hover:text-gray-500 transition-colors duration-150 ease-in button-active-bhvr" to="/blog/"><span>Blog</span></Link>
                                    </div>
                                    <Link className="block py-4 px-8 font-semibold border-b border-gray-300 font-semibold text-base hover:text-gray-500 transition-colors duration-150 ease-in button-active-bhvr" to="/contact/"><span>Contact Us</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Navbar

function Hamburger(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
        </svg>
    )
}

function WebDevInactiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
    )
}

function WebDevActiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#2971b9">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
    )
}

function WebDesignInactiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
    )
}

function WebDesignActiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#2971b9">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
    )
}

function WebMaintenanceInactiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
    )
}

function WebMaintenanceActiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#2971b9">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
    )
}

function DigitalMarketingInactiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

function DigitalMarketingActiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#2971b9">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

function SEOInactiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
        </svg>
    )
}

function SEOActiveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#2971b9">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
        </svg>
    )
}

function EcommerceInactive(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
    )
}

function EcommerceActive(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#2971b9">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
    )
}
