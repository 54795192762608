import * as React from "react"
import { Link } from "gatsby"
import Logo from "../images/pyxel-logo-white_3.png"

const Footer = () => {
    const year = new Date()
    return (
        <div style={{ backgroundColor: "#303030" }}>
            <div className="container mx-auto px-4 md:px-6 py-16">
                <div className="lg:grid md:grid-cols-2 lg:grid-cols-4 xl:gap-12">
                    <div>
                        <div className="flex" style={{ width: "150px" }}>
                            <div className="main-logo">
                                <Link to="/"><img title="Pyxel Development Web Design and SEO" alt="Pyxel Development Web Design and SEO" src={Logo} /></Link>
                            </div>
                        </div>
                        <p className='mt-6 font-light text-white text-base leading-6 ' style={{ fontSize: "12px" }}>
                            <a className="hover:text-gray-500" title="Pyxel Development Address" alt="Pyxel Development Address" href="https://www.google.com/maps/place/Pyxel+Development/@38.5780737,-121.5037324,16.97z/data=!4m5!3m4!1s0x809ad120ae47ee33:0x72ef1aa21e61d95b!8m2!3d38.5780883!4d-121.5014984?hl=en" target="_blank" rel="noreferrer">500 Capitol Mall, Suite #2350
                                <br />
                                Sacramento, CA 95814
                                <br />
                            </a>
                            <a className="hover:text-gray-500" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">916-821-9044 <br /></a>
                            <a href="mailto:admin@pyxeldev.com" title="Email Pyxel Development for Web Design or SEO" alt="Email Pyxel Development for Web Design or SEO" className="hover:text-gray-600">
                                admin@pyxeldev.com
                            </a>
                        </p>
                        <div className="flex items-center">
                            <ul className="py-7 space-x-2 flex">
                                <a className=" text-white hover:text-gray-500" href="https://www.facebook.com/pyxeldevelopment/" target="blank" rel="noreferrer" title="Visit Pyxel Development on Facebook" alt="Visit Pyxel Development on Facebook"><svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd"
                                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343
                             21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                                </svg>
                                </a>
                                <a href="https://www.instagram.com/pyxeldevelopment/" target="_blank" rel="noreferrer" title="Visit Pyxel Development on Instagram" alt="Visit Pyxel Development on Instagram" className="text-white hover:text-gray-500"><span className="sr-only">Instagram</span><svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 
                                4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 
                                01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 
                                2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 
                                2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0
                                00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                        clipRule="evenodd"></path></svg></a>
                                <a href="https://www.google.com/maps/place/Pyxel+Development/@38.5780737,-121.5037324,16.97z/data=!4m5!3m4!1s0x809ad120ae47ee33:0x72ef1aa21e61d95b!8m2!3d38.5780883!4d-121.5014984?hl=en" target="_blank" rel="noreferrer" title="Visit Pyxel Development on Google" alt="Visit Pyxel Development on Google" className="text-white hover:text-gray-500">
                                    <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 640 512"><path fill="currentColor" d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 
                                50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z">
                                    </path></svg></a>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <p className="text-sm font-bold uppercase tracking-wider text-white leading-10">Services</p>
                        <ul className="text-white font-light text-sm ">
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-web-development" title="Website Development" alt="Website Development" ><h2>Website Development</h2></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-web-design" title="Website Design" alt="Website Design"><h2>Website Design</h2></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-web-maintenance" title="Website Maintenance" alt="Website Maintenance"><h2>Website Maintenance</h2></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-digital-marketing" title="Website Digital Marketing" alt="Website Digital Marketing"><h2>Digital Marketing</h2></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-ecommerce" title="Ecommerce Website" alt="Ecommerce Website"><h2>Ecommerce</h2></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-seo" title="Website SEO" alt="Website SEO"><h2>Search Engine Optimization</h2></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="lg:mt-0 mt-6">
                        <p className="text-sm font-bold uppercase tracking-wider text-white leading-10">Site Map</p>
                        <ul className="text-white font-light text-sm ">
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/about" title="About Pyxel Development" alt="About Pyxel Development"><span>About Us</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/blog" title="Pyxel Development Blog" alt="Pyxel Development Blog"><span>Blog</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/contact" title="Contact Pyxel Development" alt="Contact Pyxel Development"><span>Contact Us</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/request-quote" title="Request a Quote" alt="Request a Quote"><span>Request Quote</span></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="lg:mt-0 mt-6">
                        <p className="text-sm font-bold uppercase tracking-wider text-white leading-10">Service Area</p>
                        <ul className="text-white font-light text-sm ">
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/sacramento-web-development" title="Sacramento Website Development" alt="Sacramento Website Development"><span>Sacramento, CA</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/elk-grove-ca-web-development" title="Elk Grove Website Development" alt="Elk Grove Website Development"><span>Elk Grove, CA</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/citrus-heights-ca-web-development" title="Citrus Heights Website Development" alt="Citrus Heights Website Development"><span>Citrus Heights, CA</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/folsom-ca-web-development" title="Folsom Website Development" alt="Folsom Website Development"><span>Folsom, CA</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/roseville-ca-web-development" title="Roseville Website Development" alt="Roseville Website Development"><span>Roseville, CA</span></Link>
                            </li>
                            <li className="pb-2 hover:text-gray-500">
                                <Link to="/all-areas-served" title="All Cities Served" alt="All Cities Served by Pyxel Development"><span>View All Areas Served</span></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="border-gray-500" />
                <div className="flex justify-center py-10 text-gray-500 text-xs font-light">
                    <p className="text-center">©{year.getFullYear()} Pyxel Development. All rights reserved.<br /><Link className="hover:text-white" to="/terms-of-use" title="Terms of Use" alt="Pyxel Development Terms of Use"><span>Terms of Use</span></Link> and <Link className="hover:text-white" to="/privacy-policy" title="Privacy Policy" alt="Pyxel Development Privacy Policy"><span>Privacy Policy</span></Link></p>
                </div>
            </div>
        </div>
    )
}
export default Footer






